/*
 * @Author: 冯杰
 * @Date: 2021-09-23 16:33:35
 * @LastEditTime: 2021-11-08 15:29:12
 * @FileName: 新增协议阶梯
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import AddPackgeLadder from './addPackgeLadder.vue';

formCreate.component('AddPackgeLadder', AddPackgeLadder);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'addLadderForm',
    };
  },
  methods: {
    formComplete() {
      if (this.formConfig.state === 'particulars') {
        this.fApi.disabled(true, ['ladderName', 'ladderType']);
        this.setValue({
          ...this.formConfig.row,
        });
        if (this.getRule('ladderType').value === 'display') {
          // this.fApi.hidden(false, 'displayNumber');
          this.fApi.disabled(true, 'displayNumber');
          this.fApi.el('AddPackgeLadder').ladderType = 'display';
        }
      }

      const ladderType = this.getRule('ladderType');
      this.fApi.hidden(true, 'displayNumber');
      ladderType.on = {
        ...ladderType.on,
        change: (name) => {
          if (name === 'quantify') {
            console.log('包量');
            this.fApi.hidden(true, 'displayNumber');
            this.fApi.el('AddPackgeLadder').ladderType = 'quantify';
          } else if (name === 'display') {
            console.log('陈列');
            this.fApi.hidden(false, 'displayNumber');
            this.fApi.el('AddPackgeLadder').ladderType = 'display';
          }
        },
      };
    },

    setRule(item) {
      const val = item;
      if (val.field === 'AddPackgeLadder') {
        val.props = {
          ...val.props,
          state: this.formConfig.state,
          rowData: this.formConfig.row,
        };
      }
      if (val.field === 'displayNumber') {
        val.props = {
          ...val.props,
          min: 0,
        };
      }
      return val;
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      let data = {};
      const addLadderTable = this.fApi.el('AddPackgeLadder');
      addLadderTable.tableInfo.data.map((va) => {
        const val = va;
        data = {
          ...val,
        };
        return val;
      });
      const subData = { ...formData, ...data };
      request.post('/cps/v1/agreement/agreementLadder', subData).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '新增成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
